import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { ConversationSummary } from '@dougs/user/dto';

@Injectable({
  providedIn: 'root',
})
export class UserConversationSummariesHttpService {
  constructor(private readonly http: HttpClient) {}

  getConversationSummaries(userId: number): Observable<ConversationSummary[]> {
    return this.http.get<ConversationSummary[]>(
      `${AppConfig.settings.legacyApiServerUrl}/users/${userId}/conversation-summaries`,
    );
  }
}
