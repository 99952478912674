import { Injectable } from '@angular/core';
import { compareAsc } from 'date-fns';
import { lastValueFrom, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Availability, Collaborator } from '@dougs/user/dto';
import { AvailabilitiesHttpService } from '../http/availabilities.http';
import { CollaboratorStateService } from './collaborator.state';

interface AvailabilitiesState {
  activeAvailabilities?: Availability[];
}

@Injectable({
  providedIn: 'root',
})
export class AvailabilitiesStateService extends StateService<AvailabilitiesState> {
  activeAvailabilities$: Observable<Availability[] | undefined> = this.select<Availability[] | undefined>((state) =>
    state.activeAvailabilities?.sort((a, b) => compareAsc(new Date(a.startsAt), new Date(b.startsAt))),
  );

  constructor(
    private readonly availabilitiesHttpService: AvailabilitiesHttpService,
    private readonly collaboratorStateService: CollaboratorStateService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshAvailabilities(collaborator: Collaborator): Promise<void> {
    try {
      this.setAvailabilitiesState(
        await lastValueFrom(this.availabilitiesHttpService.getActiveAvailabilities(collaborator.id)),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  private setAvailabilitiesState(availabilities?: Availability[]): void {
    this.setState({
      activeAvailabilities: availabilities,
    });
  }

  async addAvailability(startDate: Date, availability: number): Promise<void> {
    try {
      const activeCollaborator: Collaborator | undefined = await lastValueFrom(
        this.collaboratorStateService.activeCollaborator$.pipe(take(1)),
      );
      if (activeCollaborator) {
        await lastValueFrom(
          this.availabilitiesHttpService.addAvailability(activeCollaborator.id, startDate, availability),
        );

        this.setAvailabilitiesState(
          await lastValueFrom(this.availabilitiesHttpService.getActiveAvailabilities(activeCollaborator.id)),
        );
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async removeAvailability(availabilityId: number): Promise<void> {
    try {
      const activeCollaborator: Collaborator | undefined = await lastValueFrom(
        this.collaboratorStateService.activeCollaborator$.pipe(take(1)),
      );
      if (activeCollaborator && this.state.activeAvailabilities) {
        await lastValueFrom(this.availabilitiesHttpService.removeAvailability(activeCollaborator.id, availabilityId));

        this.setAvailabilitiesState(
          await lastValueFrom(this.availabilitiesHttpService.getActiveAvailabilities(activeCollaborator.id)),
        );
      }
    } catch (e) {
      this.logger.error(e);
    }
  }
}
