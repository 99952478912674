import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Collaborator, CollaboratorWithPersonalData } from '@dougs/user/dto';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorHttpService {
  constructor(private readonly http: HttpClient) {}

  listCollaboratorsWithPersonalData(): Observable<CollaboratorWithPersonalData[]> {
    return this.http.get<CollaboratorWithPersonalData[]>(
      `${AppConfig.settings.legacyApiServerUrl}/collaborator/list/with-personal-data`,
    );
  }

  getCollaboratorByUserId(userId: number): Observable<Collaborator | undefined> {
    return this.http.get<Collaborator | undefined>(
      `${AppConfig.settings.legacyApiServerUrl}/collaborator/by-user-id/${userId}`,
    );
  }

  addSkill(collaboratorId: number, skill: string): Observable<Collaborator> {
    return this.http.put<Collaborator>(
      `${AppConfig.settings.legacyApiServerUrl}/collaborator/${collaboratorId}/skills/add`,
      {
        skill,
      },
    );
  }

  removeSkill(collaboratorId: number, skill: string): Observable<Collaborator> {
    return this.http.put<Collaborator>(
      `${AppConfig.settings.legacyApiServerUrl}/collaborator/${collaboratorId}/skills/remove`,
      {
        skill,
      },
    );
  }

  updateProductivity(collaboratorId: number, productivity: number): Observable<Collaborator> {
    return this.http.put<Collaborator>(
      `${AppConfig.settings.legacyApiServerUrl}/collaborator/${collaboratorId}/productivity`,
      {
        productivity,
      },
    );
  }
}
