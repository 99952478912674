import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Collaborator, CollaboratorWithPersonalData, Team } from '@dougs/user/dto';
import { CollaboratorHttpService } from '../http/collaborator.http';

type ReadonlyMap<K, V> = Omit<Map<K, V>, 'set' | 'delete' | 'clear'>;

interface CollaboratorState {
  // Pour le moment, le mapping d'effectue en : UserId -> Collaborator
  // En effet, cela permet de bénéficier du legacy sur le modèle User le temps de nettoyer tout ça.
  teams: ReadonlyMap<number, Team>;
  collaborators: ReadonlyMap<number, Readonly<CollaboratorWithPersonalData>>;
  activeCollaborator?: Collaborator;
}

@Injectable({
  providedIn: 'root',
})
export class CollaboratorStateService extends StateService<CollaboratorState> {
  readonly teams$: Observable<ReadonlyMap<number, Team> | undefined> = this.select<
    ReadonlyMap<number, Team> | undefined
  >((state) => state.teams);
  readonly collaborators$: Observable<ReadonlyMap<number, Readonly<CollaboratorWithPersonalData>> | undefined> =
    this.select<ReadonlyMap<number, Readonly<CollaboratorWithPersonalData>> | undefined>(
      (state) => state.collaborators,
    );

  // Legacy ?
  readonly activeCollaborator$: Observable<Collaborator | undefined> = this.select<Collaborator | undefined>(
    (state) => state.activeCollaborator,
  );

  constructor(
    private readonly collaboratorHttpService: CollaboratorHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshCollaborator(ownerId: number): Promise<void> {
    try {
      const collaborator = await lastValueFrom(this.collaboratorHttpService.getCollaboratorByUserId(ownerId));

      this.setCollaboratorState(collaborator);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshCollaboratorsWithPersonalData(): Promise<void> {
    try {
      this.setState({
        collaborators: this.getAndSetCacheState('collaborators')
          ? this.state.collaborators
          : new Map(
              (await lastValueFrom(this.collaboratorHttpService.listCollaboratorsWithPersonalData())).map(
                (collaborator: CollaboratorWithPersonalData) => [collaborator.userId, collaborator], // UserId -> Collaborator
              ),
            ),
      });
    } catch (e) {
      this.clearCache('collaborators');
      this.logger.error(e);
    }
  }

  private setCollaboratorState(collaborator?: Collaborator): void {
    this.setState({
      activeCollaborator: collaborator,
    });
  }

  async removeSkill(skill: string): Promise<void> {
    try {
      if (this.state.activeCollaborator && this.state.activeCollaborator.skills.includes(skill)) {
        const collaborator = await lastValueFrom(
          this.collaboratorHttpService.removeSkill(this.state.activeCollaborator.id, skill),
        );
        this.setCollaboratorState(collaborator);
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addSkill(skill: string): Promise<void> {
    try {
      if (this.state.activeCollaborator && !this.state.activeCollaborator.skills.includes(skill)) {
        const collaborator = await lastValueFrom(
          this.collaboratorHttpService.addSkill(this.state.activeCollaborator.id, skill),
        );
        this.setCollaboratorState(collaborator);
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateProductivity(productivity: number): Promise<void> {
    try {
      if (this.state.activeCollaborator) {
        const collaborator: Collaborator = await lastValueFrom(
          this.collaboratorHttpService.updateProductivity(this.state.activeCollaborator.id, productivity),
        );
        this.setCollaboratorState(collaborator);
      }
    } catch (e) {
      this.logger.error(e);
    }
  }
}
