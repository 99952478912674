import { Injectable } from '@angular/core';
import { StorageKeyConfig } from '../helpers/StorageKeyConfig';
import { StorageInterface } from './storage.interface';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements StorageInterface {
  store(key: string, value: unknown): void {
    switch (typeof value) {
      case 'object':
        localStorage.setItem(StorageKeyConfig.normalize(key), JSON.stringify(value));
        break;
      case 'bigint':
      case 'number':
      case 'boolean':
      case 'symbol':
        localStorage.setItem(StorageKeyConfig.normalize(key), value.toString());
        break;
      case 'undefined':
        localStorage.setItem(StorageKeyConfig.normalize(key), 'undefined');
        break;
      case 'string':
        localStorage.setItem(StorageKeyConfig.normalize(key), value);
        break;
    }
  }

  retrieve(key: string): any {
    const value = localStorage.getItem(StorageKeyConfig.normalize(key));

    if (value === null) {
      return value;
    }
    if (value === 'undefined') {
      return undefined;
    }
    if (['true', 'false'].includes(value)) {
      return value === 'true';
    }
    if (!isNaN(Number(value))) {
      return Number(value);
    }
    try {
      return JSON.parse(value);
    } catch {
      // nothing
    }
    return value;
  }

  clear(key?: string): void {
    key ? localStorage.removeItem(StorageKeyConfig.normalize(key)) : localStorage.clear();
  }
}
