import { Injectable } from '@angular/core';
import { StorageKeyConfig } from '../helpers/StorageKeyConfig';
import { StorageInterface } from './storage.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService implements StorageInterface {
  store(key: string, value: any) {
    sessionStorage.setItem(StorageKeyConfig.normalize(key), value);
  }

  retrieve(key: string): any {
    return sessionStorage.getItem(StorageKeyConfig.normalize(key));
  }

  clear(key?: string) {
    key ? sessionStorage.removeItem(StorageKeyConfig.normalize(key)) : sessionStorage.clear();
  }
}
