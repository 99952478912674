import { IS_CASE_SENSITIVE, PREFIX } from '../constants/config';

export class StorageKeyConfig {
  static prefix = PREFIX;
  static isCaseSensitive = IS_CASE_SENSITIVE;

  static normalize(raw: string): string {
    raw = StorageKeyConfig.isCaseSensitive ? raw : raw.toLowerCase();
    return `${StorageKeyConfig.prefix}${raw}`;
  }
}
