import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Team } from '@dougs/user/dto';

@Injectable({
  providedIn: 'root',
})
export class TeamHttpService {
  constructor(private readonly http: HttpClient) {}

  list(): Observable<Team[]> {
    return this.http.get<Team[]>(`${AppConfig.settings.legacyApiServerUrl}/team/list`);
  }
}
