import { Injectable } from '@angular/core';
import { differenceInDays } from 'date-fns';
import { FlashMessage, FlashMessagesService } from '@dougs/ds';
import { UserStateService } from '../states/user.state';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userDestroyedMessage?: FlashMessage;

  constructor(
    private readonly flashMessagesService: FlashMessagesService,
    private readonly userStateService: UserStateService,
  ) {}

  async checkDestroyedUser(): Promise<void> {
    if (this.userDestroyedMessage) {
      this.flashMessagesService.close(this.userDestroyedMessage);
      this.userDestroyedMessage = undefined;
    }

    if (this.userStateService.activeUser?.deletedAt) {
      const daysBeforeDeletion = differenceInDays(new Date(this.userStateService.activeUser.deletedAt), new Date());

      this.userDestroyedMessage = this.flashMessagesService.show(
        `Votre compte va être supprimé dans ${daysBeforeDeletion} jour${
          daysBeforeDeletion > 1 ? 's' : ''
        }. Cliquez ici pour annuler la suppression.`,
        {
          type: 'warning',
          timeout: 0,
          canClose: false,
          onClick: async () => {
            if (this.userDestroyedMessage) {
              this.flashMessagesService.close(this.userDestroyedMessage);
              this.userDestroyedMessage = undefined;
            }
            const isAborted = await this.userStateService.abortUserDestroy(this.userStateService.activeUser);

            if (isAborted) {
              await this.checkDestroyedUser();
            }
          },
        },
      );
    }
  }
}
