export enum USER_FLAG {
  CAN_DELETE_STRIPE_CUSTOMER = 'canDeleteStripeCustomer',
  CAN_MANAGE_PERIOD_STARTS_AT_SUBSCRIPTION = 'canManagePeriodStartsAtSubscription',
  CAN_DELETE_TASK = 'canDeleteTask',
  CAN_SEE_INVOICING_BALANCE = 'canSeeInvoicingBalance',
  CAN_SEE_TASK_LEGAL_STATE = 'canSeeTaskLegalState',
  CAN_SEE_NEW_LEGAL_TASK_MODULE = 'canSeeNewLegalTaskModule',
  CAN_HAVE_ACCOUNTING_THIRTY_MINUTES_APPOINTMENT = 'canHaveAccountingThirtyMinutesAppointment',
  CAN_ACCESS_AND_UPDATE_SERVICE_TEMPLATES = 'canAccessAndUpdateServiceTemplates',
  HAS_ACCOUNTING_ONBOARDER = 'hasAccountingOnboarder',
  SHOW_DESIRED_PLAN_FOR_ONBOARDING_CREATION = 'showDesiredPlanForOnboardingCreation',
  CAN_REQUEST_CATEGORIZATION_HELP = 'canRequestCategorizationHelp',
}
