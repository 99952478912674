import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';

@Injectable({
  providedIn: 'root',
})
export class AvatarHttpService {
  constructor(private readonly http: HttpClient) {}

  uploadAvatar(userId: number, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/users/${userId}/profile/attachments?filename=${file.name}&type=avatar`,
      formData,
    );
  }
}
