import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Labels, PostIt, PostItCreation } from '@dougs/user/dto';

@Injectable({
  providedIn: 'root',
})
export class LabelHttpService {
  constructor(protected readonly http: HttpClient) {}

  getLabels(companyId: number): Observable<Labels> {
    return this.http.get<Labels>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/labels`);
  }

  getArchivedPostIts(companyId: number): Observable<PostIt[]> {
    return this.http.get<PostIt[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/post-its?status=archived`,
    );
  }

  getAllPostIts(companyId: number): Observable<PostIt[]> {
    return this.http.get<PostIt[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/post-its`);
  }

  updatePostIt(companyId: number, postIt: PostIt): Observable<PostIt> {
    return this.http.post<PostIt>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/post-its/${postIt.id}`,
      { title: postIt.title, content: postIt.content, isGlobal: postIt.isGlobal },
    );
  }

  restorePostIt(companyId: number, postIt: PostIt): Observable<PostIt> {
    return this.http.post<PostIt>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/post-its/${postIt.id}/restore`,
      {},
    );
  }

  archivePostIt(companyId: number, postIt: PostIt): Observable<PostIt> {
    return this.http.post<PostIt>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/post-its/${postIt.id}/archive`,
      {},
    );
  }

  uploadFile(companyId: number, postItId: number, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/post-its/${postItId}/attachments`,
      formData,
    );
  }

  removeAttachment(companyId: number, postItId: number, attachment: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/post-its/${postItId}/attachments/${attachment.id}`,
    );
  }

  createPostIt(companyId: number, postItCreation: PostItCreation): Observable<PostIt> {
    const formData: FormData = new FormData();
    formData.append('title', postItCreation.title);
    formData.append('content', postItCreation?.content ?? '');
    formData.append('isGlobal', (postItCreation?.isGlobal ?? false).toString());
    if (postItCreation.files?.length) {
      postItCreation.files?.forEach((file) => formData.append('files', file));
    }
    return this.http.post<PostIt>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/post-its/`,
      formData,
    );
  }
}
