import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Attachment } from '@dougs/core/files';
import { Job } from '@dougs/core/job-dto';

@Injectable({
  providedIn: 'root',
})
export class FileHttpService {
  constructor(private readonly http: HttpClient) {}

  deleteFile(company: Company, file: Attachment): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/attachments/${file.id}`,
    );
  }

  uploadFile(company: Company, fileType: string | undefined, file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/attachments?filename=${file.name}&type=${fileType}`,
      formData,
    );
  }

  uploadRootFile(file: File): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Attachment>(`${AppConfig.settings.legacyApiServerUrl}/files?filename=${file.name}`, formData);
  }

  generateDiaExport(attachment: Attachment): Observable<Job> {
    return this.http.get<Job>(`${AppConfig.settings.legacyApiServerUrl}/generate-dia-export?fileId=${attachment.id}`);
  }
}
