import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Availability } from '@dougs/user/dto';

@Injectable({
  providedIn: 'root',
})
export class AvailabilitiesHttpService {
  constructor(private readonly http: HttpClient) {}

  getActiveAvailabilities(collaboratorId: number): Observable<Availability[]> {
    return this.http.get<Availability[]>(
      `${AppConfig.settings.legacyApiServerUrl}/collaborator/${collaboratorId}/availabilities/`,
    );
  }

  addAvailability(collaboratorId: number, startDate: Date, availabilityValue: number): Observable<Availability> {
    return this.http.post<Availability>(
      `${AppConfig.settings.legacyApiServerUrl}/collaborator/${collaboratorId}/availability/`,
      {
        startsAt: startDate,
        availability: availabilityValue,
      },
    );
  }

  removeAvailability(collaboratorId: number, availabilityId: number): Observable<Availability> {
    return this.http.delete<Availability>(
      `${AppConfig.settings.legacyApiServerUrl}/collaborator/${collaboratorId}/availability/${availabilityId}`,
    );
  }
}
