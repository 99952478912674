import { Injectable } from '@angular/core';
import { combineLatest, concatMap, from, map, Observable, withLatestFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { LocalStorageService } from '@dougs/core/storage';
import { Labels, User, UserSearchResult } from '@dougs/user/dto';
import { LabelStateService } from '../states/label.state';
import { UserStateService } from '../states/user.state';

@Injectable({
  providedIn: 'root',
})
export class CachedUserSearchResultsService {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly labelStateService: LabelStateService,
    private readonly localStorageService: LocalStorageService,
    private readonly companyStateService: CompanyStateService,
  ) {
    this.retrieveCachedResults();
  }

  resetLabelsOwnCompany$: Observable<void> = combineLatest([
    this.companyStateService.activeCompanyIdChanged$,
    this.userStateService.loggedInUser$,
  ]).pipe(
    filter(
      ([activeCompany, loggedInUser]) =>
        activeCompany.id === (loggedInUser.companyIds?.length ? loggedInUser.companyIds[0] : loggedInUser.company.id),
    ),
    map(() => this.labelStateService.resetLabels()),
  );

  readonly storeActiveCompanyInUserCachedResults$: Observable<void> = this.userStateService.activeUserIdChanged$.pipe(
    withLatestFrom(this.companyStateService.activeCompanyIdChanged$, this.userStateService.loggedInUser$),
    filter(
      ([activeUser, activeCompany, loggedInUser]) =>
        !!activeCompany &&
        !!activeUser &&
        loggedInUser?.isAccountantOrAdmin &&
        activeCompany.id !== (loggedInUser.companyIds?.length ? loggedInUser.companyIds[0] : loggedInUser.company.id),
    ),
    concatMap(([activeUser, activeCompany, _]) =>
      from(this.labelStateService.refreshLabels(activeCompany)).pipe(
        map(
          (labels: Labels) =>
            [
              {
                ...activeUser,
                companySummary: labels,
              } as User,
              activeCompany,
            ] as [User, Company],
        ),
      ),
    ),
    map(([activeUser, activeCompany]) => this.addResult(this.buildUserResult(activeUser, activeCompany))),
  );

  private _cachedUserSearchResults: UserSearchResult[] = [];

  public get cachedUserSearchResults(): UserSearchResult[] {
    return this._cachedUserSearchResults;
  }

  public addResult(result: UserSearchResult): void {
    this._cachedUserSearchResults = [
      result,
      ...this._cachedUserSearchResults.filter((cachedResult) => cachedResult.companyId !== result.companyId),
    ].slice(0, 20);

    this.localStorageService.store('cachedSearchResults', this._cachedUserSearchResults);
  }

  public buildUserResult(user: User, activeCompany: Company | null): UserSearchResult {
    return {
      companyId: user.companyIds?.length ? user.companyIds[0] : user.company.id,
      userId: user.id,
      title: user.profile?.fullName + (user.companies[0]?.legalName ? ` - ${user.companies[0].legalName}` : ''),
      subtitle:
        activeCompany?.activity && activeCompany?.activity.length > 50
          ? activeCompany.activity?.slice(0, 50) + '...'
          : activeCompany?.activity,
      text: `${user.profile?.email}, ${user.profile?.phone}`,
      labels: user.companySummary?.pills ?? [],
      intercomRating: user.companySummary?.intercom?.rating?.toString(),
      avatar: user.profile?.avatarUrl ?? user.profile?.alternateAvatarUrl,
    };
  }

  private retrieveCachedResults(): void {
    this._cachedUserSearchResults = this.localStorageService.retrieve('cachedSearchResults') || [];
  }
}
