import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Team } from '@dougs/user/dto';
import { TeamHttpService } from '../http/team.http';

interface TeamState {
  teams: ReadonlyMap<number, Readonly<Team>>;
}

@Injectable({
  providedIn: 'root',
})
export class TeamStateService extends StateService<TeamState> {
  // This should stay temporary until we remove the fact that we use userIds for Teams.
  private readonly teamIdToUserIdMap = new Map<number, number>();

  readonly teams$: Observable<ReadonlyMap<number, Readonly<Team>> | undefined> = this.select<
    ReadonlyMap<number, Readonly<Team>> | undefined
  >((state) => state.teams);

  constructor(
    private readonly teamHttpService: TeamHttpService,
    private readonly logger: LoggerService,
  ) {
    super();
  }

  async refreshTeams(): Promise<void> {
    try {
      this.setState({
        teams: this.getAndSetCacheState('teams')
          ? this.state.teams
          : new Map(
              (await lastValueFrom(this.teamHttpService.list())).map(
                (team: Team) => [team.userId, team], // UserId -> Team
              ),
            ),
      });

      // We should keep this temporary.
      this.teamIdToUserIdMap.clear();
      Array.from(this.state.teams.values()).forEach((team) => {
        this.teamIdToUserIdMap.set(team.id, team.userId);
      });
    } catch (e) {
      this.clearCache('teams');
      this.logger.error(e);
    }
  }

  getTeamById(teamId: number): Readonly<Team> | null {
    return this.state.teams.get(this.teamIdToUserIdMap.get(teamId) ?? 0) ?? null;
  }
}
