import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { ConversationSummary } from '@dougs/user/dto';
import { UserConversationSummariesHttpService } from '../http/user-conversation-summaries.http';

interface UserConversationSummariesState {
  userConversationSummaries: ConversationSummary[];
}

@Injectable({
  providedIn: 'root',
})
export class UserConversationSummariesStateService extends StateService<UserConversationSummariesState> {
  userConversationSummaries$: Observable<ConversationSummary[]> = this.select(
    (state) => state.userConversationSummaries,
  );

  constructor(
    private readonly logger: LoggerService,
    private readonly userConversationSummariesHttpService: UserConversationSummariesHttpService,
  ) {
    super();
  }

  async getUserConversationSummaries(userId: number): Promise<void> {
    try {
      this.setState({
        userConversationSummaries: await toPromise(
          this.userConversationSummariesHttpService.getConversationSummaries(userId),
        ),
      });
    } catch (e) {
      this.logger.error(e);
      this.setState({
        userConversationSummaries: [],
      });
    }
  }
}
