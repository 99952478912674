import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Attachment } from '@dougs/core/files';
import { Job } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { FileHttpService } from '../http/file.http';

@Injectable({
  providedIn: 'root',
})
export class DiaStateService {
  constructor(
    private readonly fileHttpService: FileHttpService,
    private readonly logger: LoggerService,
  ) {}

  async uploadRootFile(file: File): Promise<Attachment | null> {
    try {
      return await lastValueFrom(this.fileHttpService.uploadRootFile(file));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async generateDiaExport(attachment: Attachment): Promise<Job | null> {
    try {
      return await lastValueFrom(this.fileHttpService.generateDiaExport(attachment));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }
}
