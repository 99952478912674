import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { Attachment, AttachmentService, StateAttachmentUtils } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { CompanyPill, IntercomRating, Labels, PostIt, PostItCreation } from '@dougs/user/dto';
import { LabelHttpService } from '../http/label.http';

interface LabelState {
  labels: Labels;
  archivedPostIts: PostIt[];
  allPostIts: PostIt[];
}

@Injectable({
  providedIn: 'root',
})
export class LabelStateService extends StateService<LabelState> {
  constructor(
    private readonly labelHttpService: LabelHttpService,
    private readonly logger: LoggerService,
    private readonly attachmentService: AttachmentService<PostIt>,
  ) {
    super();
  }

  readonly labels$: Observable<Labels> = this.select((state) => state.labels);
  readonly labelsPills$: Observable<CompanyPill[]> = this.labels$.pipe(map((labels) => labels.pills));
  readonly intercomRating$: Observable<IntercomRating | null> = this.labels$.pipe(map((labels) => labels.intercom));
  readonly postIts$: Observable<PostIt[]> = this.labels$.pipe(map((labels) => labels?.postIts || []));
  readonly archivedPostIts$: Observable<PostIt[]> = this.select((state) => state.archivedPostIts);
  readonly allPostIts$: Observable<PostIt[]> = this.select((state) => state.allPostIts);
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoading$: Observable<boolean> = this.isLoading.asObservable();

  async refreshLabels(company: Company): Promise<Labels> {
    try {
      this.isLoading.next(true);
      const labels: Labels = this.getAndSetCacheState('labels', company.id)
        ? this.state.labels
        : await lastValueFrom(this.labelHttpService.getLabels(company.id));
      this.setState({
        labels: labels,
      });
      this.isLoading.next(false);
      return labels;
    } catch (e) {
      this.logger.error(e);
      this.setState({
        labels: { pills: [], intercom: null, postIts: [] },
      });
      this.isLoading.next(false);
      return { pills: [], intercom: null, postIts: [] };
    }
  }

  async updatePostIt(companyId: number, postIt: PostIt, shouldBeVisible: boolean): Promise<PostIt | null> {
    try {
      const updatedPostIt: PostIt = await toPromise(this.labelHttpService.updatePostIt(companyId, postIt));
      this.updatePostItStateOnFormChange(updatedPostIt, shouldBeVisible);
      return updatedPostIt;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async refreshArchivedPostIts(companyId: number): Promise<void> {
    try {
      const archivedPostIts: PostIt[] = await lastValueFrom(this.labelHttpService.getArchivedPostIts(companyId));
      this.setState({
        archivedPostIts: archivedPostIts,
      });
    } catch (e) {
      this.logger.error(e);
      this.setState({
        archivedPostIts: [],
      });
    }
  }

  async refreshAllPostIts(companyId: number): Promise<void> {
    try {
      const allPostIts: PostIt[] = await lastValueFrom(this.labelHttpService.getAllPostIts(companyId));
      this.setState({
        allPostIts,
      });
    } catch (e) {
      this.logger.error(e);
      this.setState({
        allPostIts: [],
      });
    }
  }

  private updatePostItState(postIt: PostIt): void {
    this.setState({
      labels: {
        ...this.state.labels,
        postIts: this.updatePostItInList(postIt, this.state.labels.postIts),
      },
      allPostIts: this.updatePostItInList(postIt, this.state.allPostIts),
    });
  }

  private updatePostItStateOnFormChange(postIt: PostIt, shouldBeVisible: boolean): void {
    this.setState({
      labels: {
        ...this.state.labels,
        postIts: this.handleVisiblePostItUpdate(postIt, this.state.labels.postIts, shouldBeVisible),
      },
      allPostIts: this.updatePostItInList(postIt, this.state.allPostIts),
    });
  }

  async uploadPostItFiles(companyId: number, postIt: PostIt, files: FileList): Promise<void> {
    try {
      const attachmentsMap: Map<string, File> = new Map<string, File>();
      const postItWithTempAttachments: PostIt = {
        ...this.attachmentService.addAttachments(
          postIt,
          StateAttachmentUtils.getModelWithTempAttachment(files, attachmentsMap, companyId, postIt.id),
        ),
      };
      this.updatePostItState(postItWithTempAttachments);

      let postItUpdated: PostIt = postItWithTempAttachments;
      for (const [uuid, file] of Array.from(attachmentsMap)) {
        try {
          const attachmentUploaded: Attachment = await lastValueFrom(
            this.labelHttpService.uploadFile(companyId, postIt.id, file),
          );
          postItUpdated = this.attachmentService.replaceAttachment(
            postItUpdated,
            StateAttachmentUtils.addHasBeenUploadedNowToAttachment(attachmentUploaded),
            uuid,
          );
          this.updatePostItState(postItUpdated);
        } catch (e) {
          this.logger.error(e);
          postItUpdated = StateAttachmentUtils.removeAttachmentByUUIDFromModel(postItUpdated, uuid);
          this.updatePostItState(postItUpdated);
        }
      }
    } catch (e) {
      this.logger.error(e);
      this.updatePostItState(postIt);
    }
  }

  async deletePostItAttachment(companyId: number, postIt: PostIt, attachment: Attachment): Promise<void> {
    const previousAttachmentsIds: (number | string)[] = this.attachmentService.getPreviousAttachmentsIds(
      postIt?.attachments ?? [],
      attachment,
    );
    try {
      const newPostIt: PostIt = this.attachmentService.removeAttachment(postIt, attachment.id);
      this.updatePostItState(newPostIt);
      await lastValueFrom(this.labelHttpService.removeAttachment(companyId, postIt.id, attachment));
    } catch (e) {
      this.logger.error(e);
      this.updatePostItState(
        this.attachmentService.addAttachmentAfterPreviousIds(postIt, attachment, previousAttachmentsIds),
      );
    }
  }

  async createPostIt(companyId: number, postItCreation: PostItCreation): Promise<PostIt | null> {
    try {
      const postItCreated: PostIt = await toPromise(this.labelHttpService.createPostIt(companyId, postItCreation));
      if (postItCreated) {
        this.addPostItToState(postItCreated);
      }
      return postItCreated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  private addPostItToState(postItCreated: PostIt): void {
    this.setState({
      labels: {
        ...this.state.labels,
        postIts: this.addPostItToBeginningOfList(postItCreated, this.state.labels.postIts),
      },
      allPostIts: this.addPostItToBeginningOfList(postItCreated, this.state.allPostIts),
    });
  }

  async restorePostIt(companyId: number, postIt: PostIt, shouldBeVisible = false): Promise<void> {
    try {
      const restoredPostIt: PostIt = await toPromise(this.labelHttpService.restorePostIt(companyId, postIt));
      this.updateStateRestorePostIt(restoredPostIt, shouldBeVisible);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async archivePostIt(companyId: number, postIt: PostIt): Promise<void> {
    try {
      const archivedPostIt: PostIt = await toPromise(this.labelHttpService.archivePostIt(companyId, postIt));
      this.updateStateArchivePostIt(archivedPostIt);
    } catch (e) {
      this.logger.error(e);
    }
  }

  private updateStateRestorePostIt(restoredPostIt: PostIt, shouldBeVisible: boolean): void {
    this.setState({
      labels: {
        ...this.state.labels,
        postIts: shouldBeVisible
          ? this.addPostItToBeginningOfList(restoredPostIt, this.state.labels.postIts)
          : this.state.labels.postIts,
      },
      archivedPostIts: this.removePostItFromList(restoredPostIt, this.state.archivedPostIts),
      allPostIts: this.addPostItToBeginningOfList(restoredPostIt, this.state.allPostIts),
    });
  }

  private updateStateArchivePostIt(archivedPostIt: PostIt): void {
    this.setState({
      labels: {
        ...this.state.labels,
        postIts: this.removePostItFromList(archivedPostIt, this.state.labels.postIts),
      },
      archivedPostIts: this.addPostItToBeginningOfList(archivedPostIt, this.state.archivedPostIts),
      allPostIts: this.removePostItFromList(archivedPostIt, this.state.allPostIts),
    });
  }

  resetLabels(): void {
    this.setState({
      labels: {
        pills: [],
        intercom: null,
        postIts: [],
      },
      archivedPostIts: [],
    });
    this.clearCache('labels');
  }

  private handleVisiblePostItUpdate(
    postItUpdated: PostIt,
    visiblePostIts: PostIt[],
    shouldBeVisible: boolean,
  ): PostIt[] {
    return shouldBeVisible
      ? this.addOrUpdateVisiblePostIt(postItUpdated, visiblePostIts)
      : this.removePostItFromList(postItUpdated, visiblePostIts);
  }

  private addOrUpdateVisiblePostIt(postItUpdated: PostIt, visiblePostIts: PostIt[]): PostIt[] {
    const isUpdatedPostItInVisibleList: boolean = visiblePostIts.some((p) => p.id === postItUpdated.id);
    return isUpdatedPostItInVisibleList
      ? this.updatePostItInList(postItUpdated, visiblePostIts)
      : this.addPostItToBeginningOfList(postItUpdated, visiblePostIts);
  }

  private updatePostItInList(postIt: PostIt, postIts: PostIt[]): PostIt[] {
    return postIts?.map((p) => (p.id === postIt.id ? postIt : p)) ?? [];
  }

  private addPostItToBeginningOfList(postIt: PostIt, postIts: PostIt[]): PostIt[] {
    return [postIt, ...(postIts || [])];
  }

  private removePostItFromList(postIt: PostIt, postIts: PostIt[]): PostIt[] {
    return postIts?.filter((p) => p.id !== postIt.id) ?? [];
  }
}
